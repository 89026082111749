<template>
  <div id="app" class="flex flex-col min-h-screen">
    <!-- Header -->
    <Header v-if="showHeader" />

    <!-- Main Content -->
    <div class="main-content overflow-y-auto flex-grow">
      <router-view />
    </div>

    <!-- Header -->
    <Footer v-if="showFooter" />

    <!-- Session Expiry Modal -->
    <SessionContinuationModal
      :visible="isModalVisible"
      @continue-session="refreshTokenAndCloseModal"
      @close-modal="closeModal"
    />
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Header from './components/Header.vue';
import SessionContinuationModal from './components/SessionContinuationModal.vue';
import axiosInstance from './services/axiosinstance';
import Footer from './components/Footer.vue';

export default {
  components: {
    Header,
    Footer,
    SessionContinuationModal,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const isModalVisible = ref(false);

    const showHeader = computed(() => {
      return route.name !== 'Login' && route.name !== 'Register';
    });

    const showFooter = computed(() => {
      return route.name !== 'Login' && route.name !== 'Register' && route.name !== 'Home';
    });

    const refreshTokenAndCloseModal = async () => {
      isModalVisible.value = false;
      try {
        const response = await axiosInstance.post('/refresh/', {
          refresh: localStorage.getItem('refresh_token'),
        });

        const { access } = response.data;
        localStorage.setItem('access_token', access);
      } catch (error) {
        console.error('Token refresh failed:', error);
        handleSessionExpired();
      }
    };

    const handleSessionExpired = () => {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      isModalVisible.value = false;
      router.push('/login');
    };

    const closeModal = () => {
      isModalVisible.value = false;
      handleSessionExpired();
    };

    // Automatically check token expiration via Axios interceptor
    onMounted(() => {
      axiosInstance.interceptors.request.use((config) => {
        const token = localStorage.getItem('access_token');
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      });

      axiosInstance.interceptors.response.use(
        (response) => response,
        async (error) => {
          if (error.response?.status === 401) {
            const refreshToken = localStorage.getItem('refresh_token');
            if (refreshToken) {
              try {
                const refreshResponse = await axiosInstance.post('/refresh/', { refresh: refreshToken });
                const { access } = refreshResponse.data;

                // Save new access token
                localStorage.setItem('access_token', access);

                // Retry the original request with the new token
                error.config.headers.Authorization = `Bearer ${access}`;
                return axiosInstance(error.config);
              } catch (refreshError) {
                console.error('Token refresh failed:', refreshError);
                handleSessionExpired();
              }
            } else {
              handleSessionExpired();
            }
          }

          return Promise.reject(error);
        }
      );
    });

    return {
      showHeader,
      showFooter,
      isModalVisible,
      refreshTokenAndCloseModal,
      closeModal,
    };
  },
};
</script>

<template>
  <div
    v-if="visible"
    class="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50"
  >
    <div class="bg-white rounded-lg shadow-lg p-6 w-full max-w-xl relative">
      <!-- Close Button -->
      <button class="absolute top-2 right-2 text-gray-500 hover:text-gray-800" @click="closeModal">✖</button>

      <!-- Loading State -->
      <div v-if="loading" class="text-center text-gray-500 py-6">
        <div class="loader mb-4"></div>
        Loading challenge details...
      </div>

      <!-- Challenge Details -->
      <div v-else-if="challengeDetails">
        <h2 class="text-2xl font-bold mb-4">{{ challengeDetails.name }}</h2>
        <p class="mb-2">{{ challengeDetails.description }}</p>
        <p class="text-gray-700"><strong>Points:</strong> {{ challengeDetails.points }}</p>
        <p class="text-gray-700"><strong>Type:</strong> {{ challengeDetails.challenge_type }}</p>

        <!-- Flag Submission Form -->
        <form @submit.prevent="submitFlag" class="mt-4">
          <label class="block text-sm font-medium text-gray-700 mb-2">Submit Flag</label>
          <input
            type="text"
            v-model="flagText"
            class="border border-gray-300 rounded px-4 py-2 w-full mb-4 focus:outline-none focus:ring focus:ring-primary"
            placeholder="Enter the flag"
            required
          />
          <button
            type="submit"
            class="bg-primary text-white py-2 rounded w-full hover:bg-secondary transition duration-200"
          >
            Submit Flag
          </button>
        </form>
      </div>

      <!-- Error State -->
      <div v-else class="text-center text-red-500 py-6">
        Failed to load challenge details.
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import axiosInstance from '../services/axiosinstance';
import { useToast } from 'vue-toastification';

export default {
  name: 'ChallengeModal',
  props: {
    challengeId: {
      type: Number,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const challengeDetails = ref(null);
    const loading = ref(false);
    const flagText = ref('');
    const toast = useToast();

    const closeModal = () => {
      emit('close');
    };

    const fetchChallengeDetails = async () => {
      loading.value = true;
      challengeDetails.value = null; // Reset the challenge details before fetching
      try {
        // Use the new query parameter approach
        const response = await axiosInstance.get(`/challenges/?id=${props.challengeId}`);
        challengeDetails.value = response.data;
      } catch (error) {
        console.error('Error fetching challenge details:', error);
        toast.error('Failed to load challenge details.');
        emit('close');
      } finally {
        loading.value = false;
      }
    };

    const submitFlag = async () => {
      try {
        const response = await axiosInstance.post('/submit-flag/', {
          flag_text: flagText.value,
          challenge: props.challengeId,
        });
        toast.success(response.data.message || 'Flag submitted successfully!');
        closeModal();
      } catch (error) {
        toast.error(error.response?.data?.message || 'Failed to submit flag.');
      }
    };

    watch(
      () => props.visible,
      (newVal) => {
        if (newVal) {
          fetchChallengeDetails();
        } else {
          challengeDetails.value = null; // Reset challenge details on modal close
        }
      },
      { immediate: true }
    );

    return {
      challengeDetails,
      loading,
      flagText,
      closeModal,
      submitFlag,
    };
  },
};
</script>

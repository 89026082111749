<template>
  <div class="min-h-screen bg-gray-50">
    <!-- Sub Header -->
    <SubHeader
      :icon="['fas', 'home']"
      title="Welcome to Algebra CTF!"
      subtitle="Enhance your skills, compete with others, and conquer challenges."
      :titleClass="'text-4xl font-bold mb-2'"
    />

    <!-- Main Content -->
    <div class="container mx-auto p-6">
      <!-- Top Section -->
      <div class="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
        <!-- Scoreboard -->
        <div class="p-6 bg-white rounded-lg shadow-md">
          <h2 class="text-2xl font-bold mb-4 text-primary">Top 10 players</h2>
          <CTFScoreboard :maxResults="10" topResultsOnly />
          <router-link
            to="/scoreboard"
            class="mt-4 inline-block text-sm text-secondary font-semibold underline hover:text-primary"
          >
            View Full Scoreboard
          </router-link>
        </div>

        <!-- Recent Challenges -->
        <div class="p-6 bg-white rounded-lg shadow-md">
          <h2 class="text-2xl font-bold mb-4 text-primary">Recently Solved Challenges</h2>
          <RecentChallenges />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubHeader from '../components/SubHeader.vue';
import CTFScoreboard from '../components/CTFScoreboard.vue';
import RecentChallenges from '../components/RecentChallenges.vue';

export default {
  name: 'HomePage',
  components: {
    CTFScoreboard,
    RecentChallenges,
    SubHeader
  },
};
</script>

<style>
html, body {
  overflow: hidden;
}
</style>